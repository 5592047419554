import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const SocialMedia = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
     
     
    </React.Fragment>
  );
};

export default SocialMedia;
